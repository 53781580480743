import InstagramCards from "./InstagramCards/InstagramCards";

const InstagramUpdatesSecond = () => {
  return (
    <div>
      <InstagramCards />
    </div>
  );
};

export default InstagramUpdatesSecond;
